/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const join = /* GraphQL */ `
  mutation Join($input: AcceptInviteInput!) {
    join(input: $input)
  }
`;
export const deleteProgramUsers = /* GraphQL */ `
  mutation DeleteProgramUsers($input: DeleteProgramUsersInput!) {
    deleteProgramUsers(input: $input) {
      failedUserProgramJoinIds
      failedInviteIds
    }
  }
`;
export const moveProgramUsers = /* GraphQL */ `
  mutation MoveProgramUsers($input: MoveProgramUsersInput!) {
    moveProgramUsers(input: $input) {
      failedUserProgramJoinIds
      failedInviteIds
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      givenName
      familyName
      programs {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      givenName
      familyName
      programs {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      givenName
      familyName
      programs {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserProgramJoin = /* GraphQL */ `
  mutation CreateUserProgramJoin(
    $input: CreateUserProgramJoinInput!
    $condition: ModelUserProgramJoinConditionInput
  ) {
    createUserProgramJoin(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        givenName
        familyName
        programs {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      programId
      program {
        id
        createdAt
        type
        organization
        title
        managers
        users {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        groups {
          id
          name
        }
        courses {
          courseId
          startDate
          endDate
          version
        }
        invites {
          items {
            id
            programId
            programTitle
            groupId
            email
            managerEmail
            createdAt
            updatedAt
          }
          nextToken
        }
        address {
          address
          address_2
          city
          state
          country
          postal
        }
        rep {
          name
          email
        }
        feedback {
          items {
            id
            programId
            responses
            createdAt
            updatedAt
          }
          nextToken
        }
        feedbackEvaluation {
          safetyScore
        }
        demoConfig {
          blockManagement
        }
        contractId
        comments
        updatedAt
      }
      groupId
      courseProgress {
        courseId
        progress
      }
      feedbackCompleted
      isDemo
      userType
      createdAt
      updatedAt
    }
  }
`;
export const updateUserProgramJoin = /* GraphQL */ `
  mutation UpdateUserProgramJoin(
    $input: UpdateUserProgramJoinInput!
    $condition: ModelUserProgramJoinConditionInput
  ) {
    updateUserProgramJoin(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        givenName
        familyName
        programs {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      programId
      program {
        id
        createdAt
        type
        organization
        title
        managers
        users {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        groups {
          id
          name
        }
        courses {
          courseId
          startDate
          endDate
          version
        }
        invites {
          items {
            id
            programId
            programTitle
            groupId
            email
            managerEmail
            createdAt
            updatedAt
          }
          nextToken
        }
        address {
          address
          address_2
          city
          state
          country
          postal
        }
        rep {
          name
          email
        }
        feedback {
          items {
            id
            programId
            responses
            createdAt
            updatedAt
          }
          nextToken
        }
        feedbackEvaluation {
          safetyScore
        }
        demoConfig {
          blockManagement
        }
        contractId
        comments
        updatedAt
      }
      groupId
      courseProgress {
        courseId
        progress
      }
      feedbackCompleted
      isDemo
      userType
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserProgramJoin = /* GraphQL */ `
  mutation DeleteUserProgramJoin(
    $input: DeleteUserProgramJoinInput!
    $condition: ModelUserProgramJoinConditionInput
  ) {
    deleteUserProgramJoin(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        givenName
        familyName
        programs {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      programId
      program {
        id
        createdAt
        type
        organization
        title
        managers
        users {
          items {
            id
            userId
            programId
            groupId
            feedbackCompleted
            isDemo
            userType
            createdAt
            updatedAt
          }
          nextToken
        }
        groups {
          id
          name
        }
        courses {
          courseId
          startDate
          endDate
          version
        }
        invites {
          items {
            id
            programId
            programTitle
            groupId
            email
            managerEmail
            createdAt
            updatedAt
          }
          nextToken
        }
        address {
          address
          address_2
          city
          state
          country
          postal
        }
        rep {
          name
          email
        }
        feedback {
          items {
            id
            programId
            responses
            createdAt
            updatedAt
          }
          nextToken
        }
        feedbackEvaluation {
          safetyScore
        }
        demoConfig {
          blockManagement
        }
        contractId
        comments
        updatedAt
      }
      groupId
      courseProgress {
        courseId
        progress
      }
      feedbackCompleted
      isDemo
      userType
      createdAt
      updatedAt
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $input: CreateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    createProgram(input: $input, condition: $condition) {
      id
      createdAt
      type
      organization
      title
      managers
      users {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      groups {
        id
        name
      }
      courses {
        courseId
        startDate
        endDate
        version
      }
      invites {
        items {
          id
          programId
          programTitle
          groupId
          email
          managerEmail
          createdAt
          updatedAt
        }
        nextToken
      }
      address {
        address
        address_2
        city
        state
        country
        postal
      }
      rep {
        name
        email
      }
      feedback {
        items {
          id
          programId
          responses
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbackEvaluation {
        safetyScore
      }
      demoConfig {
        blockManagement
      }
      contractId
      comments
      updatedAt
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $input: UpdateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    updateProgram(input: $input, condition: $condition) {
      id
      createdAt
      type
      organization
      title
      managers
      users {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      groups {
        id
        name
      }
      courses {
        courseId
        startDate
        endDate
        version
      }
      invites {
        items {
          id
          programId
          programTitle
          groupId
          email
          managerEmail
          createdAt
          updatedAt
        }
        nextToken
      }
      address {
        address
        address_2
        city
        state
        country
        postal
      }
      rep {
        name
        email
      }
      feedback {
        items {
          id
          programId
          responses
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbackEvaluation {
        safetyScore
      }
      demoConfig {
        blockManagement
      }
      contractId
      comments
      updatedAt
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $input: DeleteProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    deleteProgram(input: $input, condition: $condition) {
      id
      createdAt
      type
      organization
      title
      managers
      users {
        items {
          id
          userId
          user {
            id
            email
            givenName
            familyName
            createdAt
            updatedAt
          }
          programId
          program {
            id
            createdAt
            type
            organization
            title
            managers
            contractId
            comments
            updatedAt
          }
          groupId
          courseProgress {
            courseId
            progress
          }
          feedbackCompleted
          isDemo
          userType
          createdAt
          updatedAt
        }
        nextToken
      }
      groups {
        id
        name
      }
      courses {
        courseId
        startDate
        endDate
        version
      }
      invites {
        items {
          id
          programId
          programTitle
          groupId
          email
          managerEmail
          createdAt
          updatedAt
        }
        nextToken
      }
      address {
        address
        address_2
        city
        state
        country
        postal
      }
      rep {
        name
        email
      }
      feedback {
        items {
          id
          programId
          responses
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbackEvaluation {
        safetyScore
      }
      demoConfig {
        blockManagement
      }
      contractId
      comments
      updatedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      programId
      programTitle
      groupId
      email
      managerEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      programId
      programTitle
      groupId
      email
      managerEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      programId
      programTitle
      groupId
      email
      managerEmail
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      programId
      responses
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      programId
      responses
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      programId
      responses
      createdAt
      updatedAt
    }
  }
`;
