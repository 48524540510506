import { 
  Injectable,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { 
  Auth,
  API, 
  graphqlOperation 
} from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { 
  onAuthUIStateChange,
  CognitoUserInterface, 
  AuthState 
} from '@aws-amplify/ui-components';
import { 
  Observable,
  from,
  of,
  forkJoin
} from 'rxjs';
import { 
  map,
  tap,
  first,
  concatMap,
  catchError
} from 'rxjs/operators';

import { getUser } from '../graphql/custom-queries';
import {
  GetUserQuery
} from '../../../models/graphql.models';

import { environment } from '../../../environments/environment';

import { CommonUIService } from '../util/common-ui.service';
import { UserService } from './user.service';
import { InviteService } from '../manage/invite.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public cognitoUser: CognitoUserInterface
  public authState: AuthState;
  public redirectUrl: string;

  private authChangeSignedIn: boolean;

  constructor(
    private router: Router,
    private zone: NgZone,
    private menuCtrl: MenuController,
    private uiService: CommonUIService,
    private userService: UserService,
    private inviteService: InviteService
  ) {
    onAuthUIStateChange((authState, authData) => {
      this.zone.run(() => {
        this.authState = authState;
        console.log(authState, authData)
        // if (authState === 'signedin') this.menuCtrl.enable(true)
        // else this.menuCtrl.enable(false);

        const route = this.router.url.split("?")[0];

        
        if (route === '/sign-in' && authState === AuthState.SignedIn) {
          if (this.authChangeSignedIn) return; // TEMP TODO Clean up whole auth process, amplify is quite shit
          this.authChangeSignedIn = true; // TEMP TODO

          this.uiService.toggleSpinner();

          this.cognitoUser = authData as CognitoUserInterface;
          const sub = this.cognitoUser.attributes.sub;
          forkJoin([this.userService.getUserData(sub), this.getAccessCookies()]).pipe(
            concatMap(() => this.inviteService.acceptPendingInvites()),
          ).subscribe(
            () => {
              this.uiService.toggleSpinner();
              this.router.navigate(
                [this.redirectUrl || '/dashboard'],
                {queryParams: {return: true}}
              );
            },
            err => {
              this.uiService.toggleSpinner();
              this.uiService.alert();
            }
          );
        } else if (authState === AuthState.SignedOut) {
          this.router.navigate(['sign-in']);
        }
      });
    });
  }

  public initAuth(): Promise<any> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      concatMap(user => {
        this.authState = AuthState.SignedIn;
        this.cognitoUser = user;
        const sub = user.attributes.sub;
        return forkJoin([this.userService.getUserData(sub), this.getAccessCookies()]).toPromise();
      }),
      catchError(() => of(null))
    ).toPromise();
  }

  public changeUserAttributes(userAttributes: Object): Observable<any> {
    return from(Auth.updateUserAttributes(this.cognitoUser, userAttributes)).pipe(
      map(async (res: string) => {
        this.cognitoUser = await Auth.currentAuthenticatedUser();
        return this.cognitoUser.attributes;
      }),
      first()
    );
  }

  public verifyEmail(code: string): Observable<any> {
    return from(Auth.verifyCurrentUserAttributeSubmit('email', code)).pipe(
      first()
    );
  }

  public resendVerificationCode(): Observable<any> {
    return from(Auth.verifyCurrentUserAttribute('email')).pipe(first())
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(Auth.changePassword(this.cognitoUser, oldPassword, newPassword)).pipe(
      first()
    )
  }

  private getAccessCookies(): Observable<void> {
    // const init = {
    //   withCredentials: true,
    //   response: true
    // };

    // return from(API.get(environment.APIG.API_NAME, environment.APIG.GET_ACCESS_COOKIES_PATH, init)).pipe(
    //   map(res => res.data),
    //   first(),
    //   catchError(err => {
    //     this.uiService.alert("We are experiencing technical issues. Please try again later. (Error: AccessCookies)");
    //     return of(null)
    //   })
    // );

    return of(null)
  }


  public async signOut(): Promise<void> {
    this.zone.run(async () => {
      await Auth.signOut();
      this.router.navigate(['sign-in']);
      this.authState = AuthState.SignedOut;
      this.authChangeSignedIn = true // TEMP TODO
    });
  }

  public ngOnDestroy() {
    return onAuthUIStateChange;
  }
}
