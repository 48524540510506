// Libraries
import { Injectable }                  from '@angular/core';
import { Router }                      from '@angular/router';
import { CanActivate, 
         CanActivateChild, 
         CanDeactivate, 
         CanLoad, 
         Route, 
         UrlSegment, 
         ActivatedRouteSnapshot, 
         RouterStateSnapshot, 
         UrlTree }                     from '@angular/router';
import { Observable }                  from 'rxjs';

// Services
import { AuthService }                 from './auth.service';
import { UserService }                 from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService, 
    private userService: UserService
  ) {}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    return (this.checkSignIn(url) && this.checkRoute(url));
  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }


  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url = route.path;
    
    return (this.checkSignIn(url) && this.checkRoute(url));
  }


  // Want to check if user is signed in first via checkSignIn, then check for route restrictions
  // once the user is signed in since checkRoute is contingent on it
  private checkSignIn(path: string): boolean {
    // If the user is signed in, return true
    if (this.authService.authState === 'signedin') return true;
    
    // If the user isn't signed in, store the url path in authService and route to sign in
    // On successful sign in, authService will redirect the user to where they were trying to go
    this.authService.redirectUrl = '/' + path;

    this.router.navigate(['/sign-in']);
    return false;
  }


  private checkRoute(url: string): boolean {
    if (url === 'manage') {
      if (this.userService.demoConfig?.blockManagement) {
        this.router.navigate(['/']);
        return false;
      }
    }

    return true;
  }
}