import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonUIService {

  public showSpinner: boolean = false;
  public darkSpinner: boolean = false;


  constructor(private alertCtrl: AlertController) { }

  public async alert(msg?: string): Promise<void> {
    const defaultMsg = 'We are experiencing technical issues. Please try again later.';

    if (msg && msg.includes(':')) {
      msg = msg.split(':')[1].trim();
    }

    const alert = await this.alertCtrl.create({
      message: msg || defaultMsg,
      buttons: ['OK']
    });

    await alert.present();
  }

  public toggleSpinner(darkSpinner = false): void {
    setTimeout(() => {
      this.showSpinner = !this.showSpinner;
      this.darkSpinner = darkSpinner; 
      
    }, 0); 
  }
}
