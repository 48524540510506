/* tslint:disable */
/* eslint-disable */

export const getUser = /* GraphQL */ `
	query GetUserData($id: ID!) {
		getUser(id: $id) {
			id
			email
			givenName
			familyName
			programs {
				items {
					id
					userId
					programId
					program {
						id
						organization
						title
						managers
						groups {
							id
							name
						}
						courses {
							courseId
							startDate
							endDate
							version
						}
						demoConfig {
							blockManagement
						}
					}
					courseProgress {
						courseId
						progress
					}
					feedbackCompleted
				}
			}
		}
	}
`;

export const getGroupUsers = /* GraphQL */ `
	query GetGroupUsers($programId: ID!, $groupId: ModelIDKeyConditionInput) {
		usersByProgramAndGroup(programId: $programId, groupId: $groupId) {
			items {
				id
				courseProgress {
					courseId
					progress
				}
				user {
					email
					givenName
					familyName
				}
			}
		}
	}
`;

export const getGroupInvites = /* GraphQL */ `
  query GetGroupInvites(
    $programId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitesByProgramAndGroup(
      programId: $programId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        programId
        groupId
        email
      }
      nextToken
    }
  }
`;


export const getGroupUsersInvites = /* GraphQL */ `
  query GetGroupUsersInvites(
    $programId: ID!
    $groupId: ModelIDKeyConditionInput!
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
		usersByProgramAndGroup(
			programId: $programId, 
			groupId: $groupId
		) {
			items {
				id
				courseProgress {
					courseId
					progress
				}
				user {
					email
					givenName
					familyName
				}
			}
		}
    invitesByProgramAndGroup(
      programId: $programId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        programId
        groupId
        email
      }
      nextToken
    }
  }
`;


export const getProgramFeedback = /* GraphQL */ `
	query GetProgramFeedback($id: ID!) {
		getProgram(id: $id) {
			id
			feedback {
				items {
					id
					responses
				}
			}
			feedbackEvaluation {
				safetyScore
			}
		}
	}
`