import { Injectable } from '@angular/core';
import { 
  API, 
  graphqlOperation 
} from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { 
  Observable,
  from,
} from 'rxjs';
import { 
  tap,
  first,
} from 'rxjs/operators';

import { getUser } from '../graphql/custom-queries';

import {
  User,
  GetUserDataQuery,
  Program,
  DemoConfig
} from '../../../models/graphql.models';


import { ProgramService } from '../program/program.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData: User;
  public managedPrograms: Partial<Program>[];
  public isManager: boolean;
  public demoConfig: DemoConfig;

  constructor(
    private programService: ProgramService,
  ) {}


  public getUserData(sub?: string): Observable<any> {
    if (!sub) sub = this.userData.id;
    return from(API.graphql(graphqlOperation(getUser, {id: sub}))).pipe(
      tap((res: GraphQLResult<GetUserDataQuery>) => this.setUserData(res.data.getUser as any)),
      first()
    );
  }

  public setUserData(userData: User): void {
    this.userData = userData; // Set user data

    // Set programs and courses from retrieved data
    this.programService.setProgramsAndCourses(userData.programs.items);

    // Set programs that the user is a manager of (if they are)
    this.managedPrograms = userData.programs.items
      .map(userProgramJoin => userProgramJoin.program)
      .filter(program => program.managers.includes(userData.id));
    this.isManager = this.managedPrograms.length > 0;
    
    // If a program has a demoConfig, store it here to indicate this is a demo user
    for (const program of this.programService.programs) {
      if (program.demoConfig) this.demoConfig = program.demoConfig;
      console.log("Demo config:", this.demoConfig);
      break;
    }
  }
}
