// Core imports
import { Injectable } from '@angular/core';

// Models
import { 
  Program,
  UserProgramJoin,
  ModelUserProgramJoinConnection,
  CourseProgress
} from '../../../models/graphql.models';
import { 
  Course,
  COURSES
} from '../../../models/course.models';


@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  public userProgramJoins: UserProgramJoin[];
  public activeUserProgramJoin: UserProgramJoin;

  public programs: Program[];
  public activeProgram: Program;

  public courses: Course[];
  
  /* This is used to map courses to their respective program in the scenario
  of multiple programs for a user, but this may be an unnecessary complication at this
  stage. Nonetheless it works, so will keep it for now. */
  public courseProgramIdx: {[courseId: string]: string} = {};

  constructor() {}

  public setProgramsAndCourses(userPrograms: UserProgramJoin[]): void {
    // Set the program joins
    this.userProgramJoins = userPrograms;

    // Set the programs
    this.programs = userPrograms.flatMap(join => join.program);

    // Merge related course info to each course and set on class
    this.courses = [];
    for (const userProgramJoin of userPrograms) {
      // Sanitize the UserProgramJoins and Programs if they are missing any properties (e.g. courseProgress on the join, groups on the program)
      if (!userProgramJoin.courseProgress) userProgramJoin.courseProgress = [];

      const program = userProgramJoin.program;
      const isManager = program.managers.includes(userProgramJoin.userId);

      if (!program.groups) program.groups = [];
      
      for (const _course of program.courses) {
        // Set the course on the index using it's ID
        this.courseProgramIdx[_course.courseId] = program.id

        // Assign the CourseProgress to this Course for easy access
        const courseProgress = userProgramJoin.courseProgress.find(courseProgress => courseProgress.courseId === _course.courseId)
        
        // Merge the standard course info to this course
        const course: Course = {
          ..._course,
          ...COURSES[_course.courseId]
        }

        // If there is progress for this specific course, parse it and attach it
        if (courseProgress && !program.demoConfig) {
          const progressPercent = courseProgress.progress / COURSES[_course.courseId].length;

          course.progress = courseProgress,
          course.progressPercent = progressPercent,
          course.complete = progressPercent === 1
        
        // Other wise initialize a blank progress for this course and attach it
        } else {
          
          (course.progress as Partial<CourseProgress>) = {
            courseId: _course.courseId,
            progress: 0
          };
          course.progressPercent = 0;
          course.complete = false;

          // If this is a demo program or the user is a manager on the program, set the course to complete
          if (program.demoConfig || isManager) {
            course.progress.progress = COURSES[course.courseId].length;
            course.progressPercent = 1;
            course.complete = true;
          }
          
          
          // Add to the courseProgress array on the userProgramJoin
          userProgramJoin.courseProgress.push(course.progress); 
        }
        
        
        const now: Date = new Date();
        const expiry: Date = new Date(course.endDate);
        let expiryPlusMonth: Date = new Date(course.endDate);
        expiryPlusMonth.setMonth(expiryPlusMonth.getMonth() + 1);
        
        if (now > expiry) course.expired = true;
        if (expiry < now && now < expiryPlusMonth) course.expiredMonth = true;
        
        // TODO Go with this route on client side and retrieve courses from program.courses
        // instead of this.courses logic
        // NOTE: Used in AccountPage for easy program course interpolation
        Object.assign(_course, course);

        // Finally, add the course to the list of courses
        this.courses.push(<Course><unknown>course)
      }

      if (program.courses.every(course => (course as any).expired)) {
        (<any>program).expired = true;
      }
    }
  }

  public getProgramCourse(courseId: string): Course {
    const programId: string = this.courseProgramIdx[courseId];
    this.setActiveProgram(programId);
    return this.courses.find(course => course.courseId === courseId);
  }

  public setActiveProgram(programId: string): Program {
    this.activeUserProgramJoin = this.userProgramJoins.find(join => join.programId === programId);
    this.activeProgram = this.programs.find(program => program.id === programId);
    return this.activeProgram;
  }

  public getCourseCards(): (Course)[] {
    return this.courses.filter(course => !course.expiredMonth);
  }
}
