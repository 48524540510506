// Core Modules
import { NgModule,
         APP_INITIALIZER }    from '@angular/core';

// Utility Modules
import { Subscription }        from 'rxjs';

// Services
import { InitializerService }      from './services/init/initializer.service';

export function initApp(InitializerService: InitializerService) {
  return () => {
    return InitializerService.initializeApp();
  }
}

@NgModule({
  providers: [
    InitializerService,
    {
      provide: APP_INITIALIZER, 
      useFactory: initApp, 
      deps: [InitializerService],
      multi: true
    }
  ]
})
export class AppLoadModule {}