import { Injectable } from '@angular/core';
import {
  API,
  graphqlOperation
} from 'aws-amplify';
import {
  Observable,
  from,
  of
} from 'rxjs';
import { 
  first,
  tap,
  concatMap
} from 'rxjs/operators';
import { AcceptInviteInput } from 'src/models/graphql.models';

import { environment } from '../../../environments/environment';

import { UserService } from '../auth/user.service';
import { join } from '../graphql/mutations';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  public pendingInvite: string;
  // private acceptingInvite: boolean = false;

  constructor(
    private userService: UserService
  ) { }

  public acceptInvite(inviteId: string): Observable<any> {
    const input: AcceptInviteInput = {inviteId};
 
    return from(API.graphql(graphqlOperation(join, {input}))).pipe(
      tap(res => this.pendingInvite = null),
      concatMap(() => this.userService.getUserData()),
      first()
    );
  }

  public acceptPendingInvites(): Observable<any> {
    if (this.pendingInvite) {
      const inviteId: string = this.pendingInvite;
      this.pendingInvite = null;

      return this.acceptInvite(inviteId).pipe(
        // tap(() => this.pendingInvite = null),
        // concatMap(() => this.userService.getUserData())
      )
    } 
    
    return of(null);
  }
}
