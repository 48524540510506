// Framework Modules
import { Injectable }         from '@angular/core';
import Auth                   from '@aws-amplify/auth';

// Services
import { AuthService }        from '../auth/auth.service'; 

@Injectable({
  providedIn: 'root'
})
export class InitializerService {
  constructor(
    private authService: AuthService
  ) {}

  public async initializeApp(): Promise<any> {
    return this.authService.initAuth();
  }
}
