import {
	Course as SchemaCourse,
	CourseId, 
	CourseProgress, 
	// ExtraComponent 	
} from "./graphql.models";

export type COURSE_ID = 'hard_target' | 'harassment' | 'healthy_relationships';

export type COURSE_TITLE = 'Hard Target' | 'Harassment' | 'Healthy Relationships';

export interface CourseDetails {
	id: string;
	type: string;
	title: COURSE_TITLE;
	description: string;
	categories: string[];
	averageDuration: string;
	length: number;
	imageSrc: string;
	link: string;
}

export interface Course extends CourseDetails {
	courseId: COURSE_ID;
	startDate: string;
	endDate: string;
	version?: string;
	expired?: boolean;
	expiredMonth?: boolean;
	
	// extra?: ExtraComponent[];

	progress?: CourseProgress; // This should be a ref to the CourseProgress returned from GQL
	progressPercent?: number;
	complete?: boolean;
}

export const COURSES: {[id: string]: CourseDetails} = {
	"hard_target": {
		id: 'hard-target',
		type: 'course',
		title: 'Hard Target',
		description: 'A Hard Target is someone who is less vulnerable to being caught off guard or targeted for violence. This course identifies the simple and practical tactics to increase situational awareness and significantly increase your safety in relevant situations that can be dangerous.',
		categories: ['Corporate', 'School'],
		averageDuration: '120 Minutes',
		length: 253,
		imageSrc: 'hard-target-card.jpg',
		link: "/course/hard-target"
	},
	"harassment": {
		id: 'harassment',
		type: 'course',
		title: 'Harassment',
		description: 'Every employee deserves to work in an environment free of bullying and sexual harassment. This course provides an in depth understanding of how to identify harassment in the workplace and how to report it.',
		categories: ['Corporate', 'School'],
		averageDuration: '60 Minutes',
		length: 126,
		imageSrc: 'harassment-card.jpg',
		link: '/course/harassment'
	},
	"healthy_relationships": {
		id: 'healthy-relationships',
		type: 'course',
		title: 'Healthy Relationships',
		description: 'This course focuses on the importance of building healthy relationships, identifying red flags of abuse, and how to generate authentic empowerment within oneself.',
		categories: ['School'],
		averageDuration: '90 Minutes',
		length: 225,
		imageSrc: 'healthy-relationships-card.jpg',
		link: '/course/healthy-relationships'
	}
}