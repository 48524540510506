import { 
  Component, 
  AfterViewInit,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

import { AuthService } from './services/auth/auth.service';
import { CommonUIService } from './services/util/common-ui.service';
import { UserService } from './services/auth/user.service';
import { User } from 'src/models/graphql.models';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  // public isManager: boolean;


  constructor(
    private router: Router,
    private menuCtrl: MenuController,
    public authService: AuthService,
    public uiService: CommonUIService,
    public userService: UserService
  ) {}

  public goTo(url: string): void {
    this.router.navigate([url]);
    this.menuCtrl.toggle('main-menu');
  }

  public ngOnInit(): void {
    // this.isManager = this.userService.isManager;
  }

  public ngAfterViewInit(): void {
    this.calcAngle(); // Calculate the moving gradient angle
  }


  // TODO Hide this stuff/put in directive
  @HostListener('window:resize')
  onResize(event) { this.calcAngle(); }

  private calcAngle(): void {
    const [width, height] = [window.innerWidth, window.innerHeight];
    const degree = Math.atan(height * 0.7 * 0.6 / width) * (180 / Math.PI);
    const body = document.getElementsByTagName('body')[0];
    body.style.setProperty('--border-degree', `${degree}deg`);
  }

  public signOut(): void {
    this.menuCtrl.toggle();
    this.authService.signOut();
  }
}
