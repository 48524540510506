import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 
  Amplify,
  { Auth }
from 'aws-amplify';
import aws_exports from './aws-exports';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// const apiConfig = {
//   API: {
//     endpoints: [
//       {
//         name: environment.httpApi.name,
//         endpoint: environment.httpApi.endpoint,
//         region: environment.httpApi.region,
//         custom_header: async () => {
//           return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`}          
//         }
//       }
//     ]
//   }
// }
// let config = Object.assign(aws_exports, apiConfig);
// Amplify.configure(config);
Amplify.configure(aws_exports);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
